var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-p-4 tw-w-full tw-h-full"},[_c('div',{staticClass:"tw-mt-4 tw-border tw-border-gray-300"},[_c('ui-data-table',{staticClass:"tw-font-sans",attrs:{"headers":_vm.headers,"isLoading":_vm.isLoading,"items":_vm.exports,"noDataMessage":"There are no exports.","hideable-columns":"","resizeable-columns":"","actions":[
        {
          label: 'Add User',
          value: function () { return _vm.createTransactionModal.trigger(); },
        } ]},scopedSlots:_vm._u([{key:"td-created",fn:function(ref){
      var item = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(item.created)+" ")])])]}},{key:"td-createdBy",fn:function(ref){
      var item = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(item.createdBy)+" ")])])]}},{key:"td-name",fn:function(ref){
      var item = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"td-email",fn:function(ref){
      var item = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(item.email)+" ")])])]}},{key:"td-actions",fn:function(ref){return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Download")])])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }