var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-p-4 tw-w-full tw-h-full"},[_c('div',{staticClass:"tw-mt-4 tw-border tw-border-gray-300"},[_c('ui-data-table',{attrs:{"headers":_vm.headers,"items":_vm.txns,"isLoading":!!_vm.isLoading,"no-data-message":"There are no rules to display."},scopedSlots:_vm._u([{key:"td-type",fn:function(ref){
var txn = ref.item;
return [_c('td',{staticClass:"tw-text-center"},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap"},_vm._l((_vm.getIconsForTxn(txn)),function(iconData,i){return _c('ui-tooltip',{key:i,attrs:{"text":iconData.text,"position":"top"}},[_c('fa',{class:iconData.class,attrs:{"icon":iconData.icon}})],1)}),1)])]}},{key:"td-id",fn:function(ref){
var txn = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(txn.id)+" ")])])]}},{key:"td-date",fn:function(ref){
var txn = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(new Date(txn.created * 1000).toLocaleString(undefined, { timeZoneName: 'short' }).replace(',', ''))+" ")])]}},{key:"td-amounts",fn:function(ref){
var txn = ref.item;
return [_c('td',_vm._l((txn.walletAmounts),function(wa){return _c('div',{key:wa.walletId + 'amounts'},_vm._l((wa.amounts),function(amt){return _c('div',{key:amt.value + amt.currencyId + 'amounts',staticClass:"tw-truncate tw-font-semibold",class:[_vm.bn(amt.value.value).toNumber() >= 0 ? 'tw-text-green-700' : 'tw-text-red-700']},[_vm._v(" "+_vm._s(_vm.numFormat(amt.value.value, { decimalPlaces: 8 }))+" ")])}),0)}),0)]}},{key:"td-tokens",fn:function(ref){
var txn = ref.item;
return [_c('td',_vm._l((txn.walletAmounts),function(wa){return _c('div',{key:wa.walletId + 'tokens'},_vm._l((wa.amounts),function(amt){return _c('div',{key:amt.value + amt.currencyId + 'tokens',staticClass:"tw-truncate tw-font-semibold",class:[_vm.bn(amt.value.value).toNumber() >= 0 ? 'tw-text-green-700' : 'tw-text-red-700']},[_vm._v(" "+_vm._s(_vm.lookupCoin(amt.currencyId))+" ")])}),0)}),0)]}},{key:"td-categorizationType",fn:function(ref){
var txn = ref.item;
return [_c('td',[(txn.categorizationSummary)?_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(txn.categorizationSummary)+" ")]):_vm._e()])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }