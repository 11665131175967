

























































import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';

export interface User {
  email: string;
  name: string;
  created: string;
  createdBy: string;
}

@Component({
  components: {
    UiDataTable,
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
  },
})
export default class Users extends BaseVue {
  public isLoading = false;

  public exports: User[] = [
    {
      created: '01-24-2023 11:34:00 AM',
      createdBy: 'Pat White',
      email: 'pat.white@bitwave.io',
      name: 'Pat White',
    },
  ];

  public headers = [
    { id: 'email', label: 'Email', defaultVisibility: true, defaultWidth: '150px' },
    { id: 'name', label: 'Name', defaultVisibility: true, defaultWidth: '150px' },
    { id: 'created', label: 'Created', defaultVisibility: true, defaultWidth: '150px' },
    { id: 'createdBy', label: 'Created By', defaultVisibility: true, defaultWidth: '150px' },
    { id: 'actions', label: 'Actions', defaultVisibility: true, defaultWidth: '83px' },
  ];
}
