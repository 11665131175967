var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-p-4 tw-w-full tw-h-full"},[_c('div',{staticClass:"tw-mt-4 tw-border tw-border-gray-300"},[_c('ui-data-table',{staticClass:"tw-font-sans",attrs:{"headers":_vm.headers,"isLoading":_vm.isLoading,"items":_vm.lines,"noDataMessage":"There are no lines.","hideable-columns":"","resizeable-columns":""},scopedSlots:_vm._u([{key:"td-timestampSEC",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":_vm.toLocalDateTime(action.timestampSEC)}})],1)])]}},{key:"td-action",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(action.action)+" ")])])]}},{key:"td-status",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.status}})],1)])]}},{key:"td-txnId",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.txnId}})],1)])]}},{key:"td-lotId",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.lotId}})],1)])]}},{key:"td-asset",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.asset}})],1)])]}},{key:"td-assetUnitAdj",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":_vm.numFormat(action.assetUnitAdj, { decimalPlaces: 6 })}})],1)])]}},{key:"td-assetBalance",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":_vm.numFormat(action.assetBalance, { decimalPlaces: 3 })}})],1)])]}},{key:"td-txnExchangeRate",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":_vm.numFormat(action.txnExchangeRate, { decimalPlaces: 6 })}})],1)])]}},{key:"td-carryingValue",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":_vm.numFormat(action.carryingValue, { decimalPlaces: 6 })}})],1)])]}},{key:"td-impairmentExpense",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":_vm.numFormat(action.impairmentExpense, { decimalPlaces: 6 })}})],1)])]}},{key:"td-proceeds",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"right","value":action.proceeds}})],1)])]}},{key:"td-shortTermGainLoss",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.numFormat(action.shortTermGainLoss, { decimalPlaces: 6 })}})],1)])]}},{key:"td-longTermGainLoss",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.numFormat(action.longTermGainLoss, { decimalPlaces: 6 })}})],1)])]}},{key:"td-undatedGainLoss",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.numFormat(action.undatedGainLoss, { decimalPlaces: 6 })}})],1)])]}},{key:"td-costBasisAquired",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.numFormat(action.costBasisAquired, { decimalPlaces: 6 })}})],1)])]}},{key:"td-costBasisRelieved",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.numFormat(action.costBasisRelieved, { decimalPlaces: 6 })}})],1)])]}},{key:"td-costAverageRate",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":_vm.numFormat(action.costAverageRate, { decimalPlaces: 6 })}})],1)])]}},{key:"td-isTrade",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(action.isTrade)+" ")])])]}},{key:"td-lineError",fn:function(ref){
var action = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('ui-truncate-text',{attrs:{"copyable":true,"position":"left","value":action.lineError}})],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }