








































































































































































































import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import UiTruncateText from '@/components/ui/UiTruncateText.vue';
import numberUtils from '@/utils/numberUtils';

export interface Export {
  id: string;
  created: string;
  createdBy: string;
  name: string;
}

@Component({
  components: {
    UiTruncateText,
    UiDataTable,
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
  },
})
export default class CostBasis extends BaseVue {
  public isLoading = false;

  public lines: any[] = [
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1655596799,
      timestamp: {
        value: '2022-06-18T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a',
      eventId: 'IMPAIRMENT-0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0-1655596799',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0',
      assetBalance: '0.006338952963593152',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '883.48',
      carryingValue: '5.60033816427527792896',
      impairmentExpense: '0.82780386751562971968',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1655337599,
      timestamp: {
        value: '2022-06-15T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a',
      eventId: 'IMPAIRMENT-0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0-1655337599',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0',
      assetBalance: '0.006338952963593152',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '1014.07',
      carryingValue: '6.42814203179090764864',
      impairmentExpense: '0.38902154337571173824',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1655251199,
      timestamp: {
        value: '2022-06-14T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a',
      eventId: 'IMPAIRMENT-0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0-1655251199',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0',
      assetBalance: '0.006338952963593152',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '1075.44',
      carryingValue: '6.81716357516661938688',
      impairmentExpense: '0.57158338872719451584',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1655164799,
      timestamp: {
        value: '2022-06-13T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a',
      eventId: 'IMPAIRMENT-0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0-1655164799',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0',
      assetBalance: '0.006338952963593152',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '1165.61',
      carryingValue: '7.38874696389381390272',
      impairmentExpense: '1.65370604914218149376',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1655164799,
      timestamp: {
        value: '2022-06-13T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013',
      eventId: 'IMPAIRMENT-0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq-1655164799',
      lotId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq',
      asset: 'LINK',
      assetId: 'COIN.1023',
      assetUnitAdj: '0',
      assetBalance: '87.585970161457770522',
      lotAcquisitionTimestampSEC: 1653310896,
      txnExchangeRate: '5.295',
      carryingValue: '463.76771200491889491399',
      impairmentExpense: '78.8273731453119934698',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1655078399,
      timestamp: {
        value: '2022-06-12T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a',
      eventId: 'IMPAIRMENT-0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0-1655078399',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0',
      assetBalance: '0.006338952963593152',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '1426.49',
      carryingValue: '9.04245301303599539648',
      impairmentExpense: '0.46825845542062613824',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1654991999,
      timestamp: {
        value: '2022-06-11T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a',
      eventId: 'IMPAIRMENT-0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0-1654991999',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0',
      assetBalance: '0.006338952963593152',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '1500.36',
      carryingValue: '9.51071146845662153472',
      impairmentExpense: '0.97797366322315149056',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1654905599,
      timestamp: {
        value: '2022-06-10T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a',
      eventId: 'IMPAIRMENT-0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0-1654905599',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0',
      assetBalance: '0.006338952963593152',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '1654.64',
      carryingValue: '10.48868513167977302528',
      impairmentExpense: '0.33159062952555778112',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653782399,
      timestamp: {
        value: '2022-05-28T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013',
      eventId: 'IMPAIRMENT-0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq-1653782399',
      lotId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq',
      asset: 'LINK',
      assetId: 'COIN.1023',
      assetUnitAdj: '0',
      assetBalance: '87.585970161457770522',
      lotAcquisitionTimestampSEC: 1653310896,
      txnExchangeRate: '6.195',
      carryingValue: '542.59508515023088838379',
      impairmentExpense: '1.576547462906239869396',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653695999,
      timestamp: {
        value: '2022-05-27T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a',
      eventId: 'IMPAIRMENT-0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0-1653695999',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0',
      assetBalance: '0.006338952963593152',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '1706.95',
      carryingValue: '10.8202757612053308064',
      impairmentExpense: '0.21717252853270138752',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653695999,
      timestamp: {
        value: '2022-05-27T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013',
      eventId: 'IMPAIRMENT-0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq-1653695999',
      lotId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq',
      asset: 'LINK',
      assetId: 'COIN.1023',
      assetUnitAdj: '0',
      assetBalance: '87.585970161457770522',
      lotAcquisitionTimestampSEC: 1653310896,
      txnExchangeRate: '6.213',
      carryingValue: '544.171632613137128253186',
      impairmentExpense: '0.788273731453119934698',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653609599,
      timestamp: {
        value: '2022-05-26T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a',
      eventId: 'IMPAIRMENT-0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0-1653609599',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0',
      assetBalance: '0.006338952963593152',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '1741.21',
      carryingValue: '11.03744828973803219392',
      impairmentExpense: '1.07324812626595656512',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653609599,
      timestamp: {
        value: '2022-05-26T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013',
      eventId: 'IMPAIRMENT-0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq-1653609599',
      lotId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq',
      asset: 'LINK',
      assetId: 'COIN.1023',
      assetUnitAdj: '0',
      assetBalance: '87.585970161457770522',
      lotAcquisitionTimestampSEC: 1653310896,
      txnExchangeRate: '6.222',
      carryingValue: '544.959906344590248187884',
      impairmentExpense: '43.092297319437223096824',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653436799,
      timestamp: {
        value: '2022-05-24T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a',
      eventId: 'IMPAIRMENT-0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0-1653436799',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0',
      assetBalance: '0.006338952963593152',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '1910.52',
      carryingValue: '12.11069641600398875904',
      impairmentExpense: '0.28265391264661864768',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653436799,
      timestamp: {
        value: '2022-05-24T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013',
      eventId: 'IMPAIRMENT-0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq-1653436799',
      lotId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq',
      asset: 'LINK',
      assetId: 'COIN.1023',
      assetUnitAdj: '0',
      assetBalance: '87.585970161457770522',
      lotAcquisitionTimestampSEC: 1653310896,
      txnExchangeRate: '6.714',
      carryingValue: '588.052203664027471284708',
      impairmentExpense: '23.385454033109224729374',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653350399,
      timestamp: {
        value: '2022-05-23T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a',
      eventId: 'IMPAIRMENT-0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0-1653350399',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0',
      assetBalance: '0.006338952963593152',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '1955.11',
      carryingValue: '12.39335032865060740672',
      impairmentExpense: '0.6928898186071554679466666666666666666666666666666666666666666688',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653350399,
      timestamp: {
        value: '2022-05-23T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013',
      eventId: 'IMPAIRMENT-0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq-1653350399',
      lotId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq',
      asset: 'LINK',
      assetId: 'COIN.1023',
      assetUnitAdj: '0',
      assetBalance: '87.585970161457770522',
      lotAcquisitionTimestampSEC: 1653310896,
      txnExchangeRate: '6.981',
      carryingValue: '611.437657697136696014082',
      impairmentExpense: '54.07134230286330398591799999999999999999999999999999999999999999',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653310896,
      timestamp: {
        value: '2022-05-23T13:01:36.000Z',
      },
      action: 'sell',
      status: 'complete',
      txnId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013',
      eventId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-disp',
      lotId: '0x5ba6a67e5bdbee682ed41aade48855bdf1efdfe268db2287887489c2c5462896.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '-0.05',
      assetBalance: '0.01',
      lotAcquisitionTimestampSEC: 1653058883,
      txnExchangeRate: '2056.190625',
      carryingValue: '0',
      impairmentExpense: null,
      fairMarketValueDisposed: '102.80953125',
      shortTermGainLoss: '6.77903125',
      longTermGainLoss: '0',
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: '96.0305',
      costAverageRate: null,
      isTrade: true,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653310896,
      timestamp: {
        value: '2022-05-23T13:01:36.000Z',
      },
      action: 'sell',
      status: 'complete',
      txnId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013',
      eventId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-disp',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '-0.11',
      assetBalance: '0.01',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '2056.190625',
      carryingValue: '20.64416666666666666666666666666666666666666666666666666666666667',
      impairmentExpense: null,
      fairMarketValueDisposed: '226.18096875',
      shortTermGainLoss: '-0.9048645833333333333333333333333333333333333333333333333333334',
      longTermGainLoss: '0',
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: '227.0858333333333333333333333333333333333333333333333333333333334',
      costAverageRate: null,
      isTrade: true,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653310896,
      timestamp: {
        value: '2022-05-23T13:01:36.000Z',
      },
      action: 'sell',
      status: 'complete',
      txnId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013',
      eventId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-disp',
      lotId: '0x3fe0d0564ff8ec179c7cd308db700ac7db929e6060e4a0c725d8a9dfe3c87028.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '-0.06',
      assetBalance: '0.01',
      lotAcquisitionTimestampSEC: 1653308442,
      txnExchangeRate: '2056.190625',
      carryingValue: '0',
      impairmentExpense: null,
      fairMarketValueDisposed: '123.3714375',
      shortTermGainLoss: '-0.4985625',
      longTermGainLoss: '0',
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: '123.87',
      costAverageRate: null,
      isTrade: true,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653310896,
      timestamp: {
        value: '2022-05-23T13:01:36.000Z',
      },
      action: 'sell',
      status: 'complete',
      txnId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013',
      eventId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-disp',
      lotId: '0xa9524075b5c6b827a367f7882da683a2c114a120cfa5e0f9af34bdd9bedd5b86.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '-0.05',
      assetBalance: '0.01',
      lotAcquisitionTimestampSEC: 1653307078,
      txnExchangeRate: '2056.190625',
      carryingValue: '0',
      impairmentExpense: null,
      fairMarketValueDisposed: '102.80953125',
      shortTermGainLoss: '-0.53046875',
      longTermGainLoss: '0',
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: '103.34',
      costAverageRate: null,
      isTrade: true,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653310896,
      timestamp: {
        value: '2022-05-23T13:01:36.000Z',
      },
      action: 'sell',
      status: 'complete',
      txnId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013',
      eventId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-disp',
      lotId: '0x140988250b84b09f9a3b2c0d16b8fdb2f0389bf49d782b560dea27c653529cba.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '-0.05',
      assetBalance: '0.01',
      lotAcquisitionTimestampSEC: 1652992005,
      txnExchangeRate: '2056.190625',
      carryingValue: '0',
      impairmentExpense: null,
      fairMarketValueDisposed: '102.80953125',
      shortTermGainLoss: '7.76403125',
      longTermGainLoss: '0',
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: '95.0455',
      costAverageRate: null,
      isTrade: true,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653310896,
      timestamp: {
        value: '2022-05-23T13:01:36.000Z',
      },
      action: 'sell',
      status: 'complete',
      txnId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013',
      eventId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.1-disp',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '-0.003661047',
      assetBalance: '0.006338952963593152',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '2056.242360488323949553333839705446831080680594585097065509060132',
      carryingValue: '13.08624014725776287466666666666666666666666666666666666666666667',
      impairmentExpense: null,
      fairMarketValueDisposed: '7.527999999999999999999999999999999999999999999999999999999999998',
      shortTermGainLoss: '-0.029926519408903792000000000000000000000000000000000000000000003',
      longTermGainLoss: '0',
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: '7.557926519408903792000000000000000000000000000000000000000000001',
      costAverageRate: null,
      isTrade: true,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653310896,
      timestamp: {
        value: '2022-05-23T13:01:36.000Z',
      },
      action: 'buy',
      status: 'complete',
      txnId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013',
      eventId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq',
      lotId: '0xf0d75930e4839ecbc4df20f287f45c1b63e507e5185771deebd108a05a031013.0-acq',
      asset: 'LINK',
      assetId: 'COIN.1023',
      assetUnitAdj: '87.5859701615',
      assetBalance: '87.585970161457770522',
      lotAcquisitionTimestampSEC: 1653310896,
      txnExchangeRate: '7.598351639802437360633394449026185843226049329081854767977677611',
      carryingValue: '665.509',
      impairmentExpense: null,
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: '665.509',
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: true,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653310756,
      timestamp: {
        value: '2022-05-23T12:59:16.000Z',
      },
      action: 'buy',
      status: 'complete',
      txnId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a',
      eventId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      lotId: '0x365838ad25d081be2f3a1d9dcd2a77d9e9474add75b6d339286401e50574551a.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0.12',
      assetBalance: '0.33',
      lotAcquisitionTimestampSEC: 1653310756,
      txnExchangeRate: '2064.416666666666666666666666666666666666666666666666666666666667',
      carryingValue: '247.73',
      impairmentExpense: null,
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: '247.73',
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653308442,
      timestamp: {
        value: '2022-05-23T12:20:42.000Z',
      },
      action: 'buy',
      status: 'complete',
      txnId: '0x3fe0d0564ff8ec179c7cd308db700ac7db929e6060e4a0c725d8a9dfe3c87028',
      eventId: '0x3fe0d0564ff8ec179c7cd308db700ac7db929e6060e4a0c725d8a9dfe3c87028.0.0',
      lotId: '0x3fe0d0564ff8ec179c7cd308db700ac7db929e6060e4a0c725d8a9dfe3c87028.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0.06',
      assetBalance: '0.21',
      lotAcquisitionTimestampSEC: 1653308442,
      txnExchangeRate: '2064.5',
      carryingValue: '123.87',
      impairmentExpense: null,
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: '123.87',
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653307078,
      timestamp: {
        value: '2022-05-23T11:57:58.000Z',
      },
      action: 'buy',
      status: 'complete',
      txnId: '0xa9524075b5c6b827a367f7882da683a2c114a120cfa5e0f9af34bdd9bedd5b86',
      eventId: '0xa9524075b5c6b827a367f7882da683a2c114a120cfa5e0f9af34bdd9bedd5b86.0.0',
      lotId: '0xa9524075b5c6b827a367f7882da683a2c114a120cfa5e0f9af34bdd9bedd5b86.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0.05',
      assetBalance: '0.15',
      lotAcquisitionTimestampSEC: 1653307078,
      txnExchangeRate: '2066.8',
      carryingValue: '103.34',
      impairmentExpense: null,
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: '103.34',
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653091199,
      timestamp: {
        value: '2022-05-20T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0x5ba6a67e5bdbee682ed41aade48855bdf1efdfe268db2287887489c2c5462896',
      eventId: 'IMPAIRMENT-0x5ba6a67e5bdbee682ed41aade48855bdf1efdfe268db2287887489c2c5462896.0.0-1653091199',
      lotId: '0x5ba6a67e5bdbee682ed41aade48855bdf1efdfe268db2287887489c2c5462896.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0',
      assetBalance: '0.05',
      lotAcquisitionTimestampSEC: 1653058883,
      txnExchangeRate: '1920.61',
      carryingValue: '96.0305',
      impairmentExpense: '1.9095',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653058883,
      timestamp: {
        value: '2022-05-20T15:01:23.000Z',
      },
      action: 'buy',
      status: 'complete',
      txnId: '0x5ba6a67e5bdbee682ed41aade48855bdf1efdfe268db2287887489c2c5462896',
      eventId: '0x5ba6a67e5bdbee682ed41aade48855bdf1efdfe268db2287887489c2c5462896.0.0',
      lotId: '0x5ba6a67e5bdbee682ed41aade48855bdf1efdfe268db2287887489c2c5462896.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0.05',
      assetBalance: '0.1',
      lotAcquisitionTimestampSEC: 1653058883,
      txnExchangeRate: '1958.8',
      carryingValue: '97.94',
      impairmentExpense: null,
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: '97.94',
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1653004799,
      timestamp: {
        value: '2022-05-19T23:59:59.000Z',
      },
      action: 'impairment',
      status: 'complete',
      txnId: '0x140988250b84b09f9a3b2c0d16b8fdb2f0389bf49d782b560dea27c653529cba',
      eventId: 'IMPAIRMENT-0x140988250b84b09f9a3b2c0d16b8fdb2f0389bf49d782b560dea27c653529cba.0.0-1653004799',
      lotId: '0x140988250b84b09f9a3b2c0d16b8fdb2f0389bf49d782b560dea27c653529cba.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0',
      assetBalance: '0.05',
      lotAcquisitionTimestampSEC: 1652992005,
      txnExchangeRate: '1900.91',
      carryingValue: '95.0455',
      impairmentExpense: '5.6345',
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: null,
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
    {
      orgId: 'jtMvU6kEq7AF2DJIupb4',
      runId: 'd772f19fb423107ec09595a83901b0',
      timestampSEC: 1652992005,
      timestamp: {
        value: '2022-05-19T20:26:45.000Z',
      },
      action: 'buy',
      status: 'complete',
      txnId: '0x140988250b84b09f9a3b2c0d16b8fdb2f0389bf49d782b560dea27c653529cba',
      eventId: '0x140988250b84b09f9a3b2c0d16b8fdb2f0389bf49d782b560dea27c653529cba.0.0',
      lotId: '0x140988250b84b09f9a3b2c0d16b8fdb2f0389bf49d782b560dea27c653529cba.0.0',
      asset: 'ETH',
      assetId: 'COIN.10',
      assetUnitAdj: '0.05',
      assetBalance: '0.05',
      lotAcquisitionTimestampSEC: 1652992005,
      txnExchangeRate: '2013.6',
      carryingValue: '100.68',
      impairmentExpense: null,
      fairMarketValueDisposed: null,
      shortTermGainLoss: null,
      longTermGainLoss: null,
      undatedGainLoss: null,
      costBasisAcquired: '100.68',
      costBasisRelieved: null,
      costAverageRate: null,
      isTrade: false,
      lineError: null,
    },
  ];

  public numFormat = numberUtils.getFormatter({ accountingNegative: true });

  public get headers() {
    const headers = [
      {
        id: 'timestampSEC',
        label: 'Timestamp',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'action',
        label: 'Action',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'status',
        label: 'Status',
        defaultVisibility: true,
        sortable: true,
        filterable: true,
      },
      {
        id: 'txnId',
        label: 'Txn ID',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'lotId',
        label: 'Lot ID',
        defaultVisibility: false,
        sortable: true,
      },
      {
        id: 'asset',
        label: 'Asset',
        defaultVisibility: true,
        sortable: true,
        filterable: true,
      },
      {
        id: 'assetUnitAdj',
        label: 'Asset Unit Adj',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'assetBalance',
        label: 'Asset Balance',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'txnExchangeRate',
        label: 'Txn Exchange Rate',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'carryingValue',
        label: 'Carrying Value',
        defaultVisibility: true,
        sortable: true,
      },
    ];

    headers.push(
      ...[
        {
          id: 'proceeds',
          label: 'Proceeds',
          defaultVisibility: false,
          sortable: true,
        },
      ]
    );
    headers.push(
      ...[
        {
          id: 'costBasisAcquired',
          label: 'Cost Basis Acquired',
          defaultVisibility: false,
          sortable: true,
        },
        {
          id: 'costBasisRelieved',
          label: 'Cost Basis Relieved',
          defaultVisibility: true,
          sortable: true,
        },
      ]
    );
    headers.push(
      ...[
        {
          id: 'isTrade',
          label: 'Is Trade',
          defaultVisibility: false,
          sortable: true,
        },
        {
          id: 'lineError',
          label: 'Line Error',
          defaultVisibility: false,
          sortable: true, // line error only true if there is an error
        },
      ]
    );

    return headers;
  }
}
