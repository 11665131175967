






































































import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { PricingRule, Transaction, TxnType } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import numberUtils from '@/utils/numberUtils';

import { baConfig } from '../../../../config';
import { ApiSvcTransactionLite, ClientDataApi } from '../../../../generated/api-svc';

@Component({
  components: {
    UiDataTable,
    UiSelect,
  },
})
export default class Transactions extends BaseVue {
  @Prop({ default: null })
  public readonly selectedClient?: string | null;

  public txns: ApiSvcTransactionLite[] = [];

  public isLoading = false;

  public headers = [
    { id: 'type', label: 'Type', defaultVisibility: true, defaultWidth: '52px' },
    { id: 'id', label: 'Transaction Id', defaultVisibility: true, defaultWidth: '150px' },
    { id: 'date', label: 'Date', defaultVisibility: true, defaultWidth: '196px' },
    { id: 'amounts', label: 'Amounts', defaultVisibility: true, defaultWidth: '94px' },
    { id: 'tokens', label: 'Tokens', defaultVisibility: true, defaultWidth: '83px' },
  ];

  public numFormat = numberUtils.format;

  public coinLookup = new Map<string, string>();

  public lookupCoin(currencyId: string) {
    return this.coinLookup.get(currencyId) ?? currencyId;
  }

  @Watch('selectedClient')
  public async onSelectedClientChanged() {
    await this.loadTransactions();
  }

  async loadTransactions() {
    if (this.selectedClient) {
      this.isLoading = true;
      try {
        const clientApi = new ClientDataApi(undefined, baConfig.getFriendlyApiUrl());
        const resp = await clientApi.getClientTransactions(this.orgId, this.selectedClient, undefined, {
          withCredentials: true,
        });
        this.txns = resp.data.items;
        this.coinLookup = resp.data ? new Map(resp.data.coins.map((c: any) => [c.currencyId, c.ticker])) : new Map();
      } catch (e) {
        this.showErrorSnackbar('Problem loading transactions');
      } finally {
        this.isLoading = false;
      }
    }
  }

  public getIconsForTxn(txn: Transaction) {
    const icons = [] as { icon: string; class: string; text: string }[];
    const iconDatas = {
      [TxnType.Receive]: {
        icon: 'fa-arrow-circle-left',
        class: 'tw-text-success-300 tw-transform tw--rotate-45',
        text: 'Received',
      },
      [TxnType.Send]: {
        icon: 'fa-arrow-circle-right',
        class: `tw-text-error-300 tw-transform tw--rotate-45`,
        text: 'Send',
      },
      [TxnType.Trade]: {
        icon: 'fa-exchange',
        class: 'tw-text-primary-300',
        text: 'Trade',
      },
      [TxnType.Transfer]: {
        icon: 'fa-refresh',
        class: 'tw-text-primary-300',
        text: 'Transfer',
      },
      [TxnType.ContractExecution]: {
        icon: 'fa-code',
        class: 'tw-text-primary-300',
        text: 'Contract execution',
      },
    } as Record<TxnType, typeof icons[number]>;
    if (txn.type) {
      if (txn.type in iconDatas) {
        icons.push(iconDatas[txn.type]);
      }
      if (txn.type === TxnType.Receive && txn.hasMatchedInvoices) {
        icons.push({
          icon: 'fa-credit-card-alt',
          class: 'tw-text-primary-300',
          text: 'Invoice payment',
        });
      }
    }
    if (txn.isCombined) {
      icons.push({
        icon: 'fa-compress',
        class: 'tw-text-primary-300',
        text: 'Combined transaction',
      });
    }
    if (txn.isCombinedSubTransaction) {
      icons.push({
        icon: 'fa-sign-in',
        class: 'tw-text-primary-300 tw-transform tw-rotate-180',
        text: 'Combined sub-transaction',
      });
    }
    if (txn.errored) {
      icons.push({
        icon: 'fa-exclamation-circle',
        class: 'tw-text-error-300',
        text: txn.errors?.join('\n') ?? 'Errored',
      });
    }
    return icons;
  }
}
